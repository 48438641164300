.content-block.inhalt {
  @extend .container-fluid;
  position: relative;

  .container {
    padding-top: $grid-gutter-width * 1.25;
    padding-bottom: $grid-gutter-width * 1.25;
  }

  &.bild {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;

    &,
    .title-bold-pos,
    .button {
      color: $white;
    }
    .button {
      border: 1px solid $white;
    }
    figure {
      .triangle {
        display: none;
      }
    }
  }
  &.farbe {
    background-color: $beige;
  }
  figure {
    position: relative;
    margin-bottom: $grid-gutter-width * 0.5;
    .triangle {
      @include triangle(topright, 80px, 80px, $white);
    }
  }
  .button {
    @extend .button;
  }
}
