.nav {
  li {
    color: $white;
    float: none;
    @media (min-width: $screen-md-min) {
      color: $brand-primary;
      float: left;
    }
    a {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      color: $white;
      @media (min-width: $screen-md-min) {
        color: $brand-primary;
      }
    }

    &.normal {
      ul {

        list-style: none;
        display: none;
        padding: 20px 0 20px 20px;


        @media (min-width: $screen-md-min) {
          background-color: white;
          position: absolute;
          left: 0;
          min-width: 100%;
          top: 100%;
          padding: 0;
          margin: 0;
        }

        li {
          float: none;
        }

        a {
          font-weight: normal;
          display: block;
          padding: 5px 15px;

          &:first-child {
            padding-top: 10px;
          }

          &:last-child {
            padding-bottom: 10px;
          }
        }

        a:hover {
          text-decoration: none;
        }
      }
    }

    > ul.languages {
      padding: 10px 15px 0 15px;
      margin: 0;

      > li {
        padding: 0;
        a {
          font-weight: normal;
          color: $white;

          @media (min-width: $screen-md-min) {
            color: $brand-primary;
          }
        }
      }

      @media screen and (min-width: $screen-md-min) {
        position: absolute;
        min-width: 74px;
        text-align: right;
        right: 0;
        top: -25px;
      }
    }

    &:hover > a,
    &.current-menu-item > a,
    &.current_page_parent > a,
    a:hover,
    a:focus {
      background: none;
      color: $brand-primary;
      @media (min-width: $screen-md-min) {
        color: $gray;
      }
    }
  }
}

.no-touchevents {
  .nav li.normal:hover ul {
    display: block;
  }
}
.touchevents {
  .nav li.normal.open-sub ul {
    display: block;
  }
}
