// --------------------
// Typography
// --------------------

body {
  font-family: $font-family-sans-serif;
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  @media (min-width: $screen-md-min) {
    font-size: 16px;
    line-height: 1.5;
  }
  @media (min-width: $screen-lg-min) {
    font-size: 18px;
  }
}

.h1, .h2, .h3, h1, h2, h3 {
  margin-top: initial;
}

.maintitle-bold-neg {
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.08;
  color: #ffffff;

  @media (min-width: $screen-sm-min) {
    font-size: 32px;
  }
  @media (min-width: $screen-md-min) {
    font-size: 38px;
    line-height: 1.16;
  }
  @media (min-width: $screen-lg-min) {
    font-size: 48px;
    line-height: 1.2;
  }
}

.title-bold-pos {
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  color: $brand-primary;
  @media (min-width: $screen-sm-min) {
    font-size: 32px;
  }
  @media (min-width: $screen-lg-min) {
    font-size: 48px;
    line-height: 1.2;
  }
}

.subtitle-bold-pos {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  color: $brand-primary;
  @media (min-width: $screen-sm-min) {
    font-size: 21px;
  }
  @media (min-width: $screen-lg-min) {
    font-size: 32px;
    line-height: 1.2;
  }
}


// --------------------
// Global Elements
// --------------------

ul {
  padding-left: 18px;
}

.content-anchor {
  content: "";
  display: block;
  height: 105px;
  margin: -105px 0 0;
  pointer-events: none;
  z-index: -1;
  &.flex-anchor {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

// --------------------
// Privacy Cookie Warning
// --------------------

.cookie_warning {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: white;
  z-index: 5;
  color: $gray;
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 20px;

  .cookie_warning_accepted & {
    display: none;
  }

  main {
    @extend .col-xs-12;
    @extend .col-sm-8;
    @extend .col-md-9;

    margin-bottom: 20px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }

    :first-child {
      margin-top: 0;
    }
    :last-child {
      margin-bottom: 0;
    }

    a {
      color: $red;
    }
  }

  footer {
    @extend .col-xs-12;
    @extend .col-sm-4;
    @extend .col-md-3;

    @media (min-width: $screen-sm-min) {
      text-align: right;
    }

    div {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .button {
        margin-bottom: 0;

        :hover {
          text-decoration: none;
          color: #78777c;
        }
      }
    }
  }
}
