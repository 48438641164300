.content-block.team {
  @extend .container;
  padding-top: $grid-gutter-width * 1.25;
  padding-bottom: $grid-gutter-width * 1.25;
  .title-bold-pos {
    margin-bottom: $grid-gutter-width * 0.75;
  }
  .wrapper-team-mitglied {
    .team-mitglied {
      @include make-sm-column(4);
      @include make-lg-column(3);
      padding-bottom: $grid-gutter-width * 2;
      @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
        &:nth-child(3n+1) { clear: left; }
      }
      @media (min-width: $screen-lg-min) {
        &:nth-child(4n+1) { clear: left; }
      }
      figure {
        padding-bottom: $grid-gutter-width * 0.75;
        position: relative;
        .triangle {
          @include triangle(topright, 40px, 40px, $white);
        }
      }
    }
  }
}