.content-block.values {
  @extend .container;
  padding-top: $grid-gutter-width * 1.25;
  padding-bottom: $grid-gutter-width * 1.25;

  > header {
    h3 {
      @extend .title-bold-pos;
      margin-bottom: $grid-gutter-width;
    }
  }

  > main {
    section {
      margin-bottom: $grid-gutter-width;

      header {
        margin-bottom: $grid-gutter-width;

        h4 {
          margin: 0;
          @extend .subtitle-bold-pos;
        }
      }
    }
  }
}
