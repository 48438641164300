.acf-map {
  width: 100%;
  height: 400px;
}

/* fixes potential theme css conflict */
.acf-map img {
  max-width: inherit !important;
}

.acf-map>.gm-style {
  pointer-events: none;
}