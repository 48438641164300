.comment-list {
  @include list-unstyled;
}
.comment-list ol {
  list-style: none;
}
.comment-form p {
  @extend .form-group;
}
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {
  @extend .form-control;
}
.comment-form input[type="submit"] {
  @extend .btn;
  @extend .btn-primary;
}

@media screen and (max-width: $screen-xs-max) {
}
@media screen and (min-width: $screen-sm-min) {
}
@media screen and (min-width: $screen-md-min) {
}
@media screen and (min-width: $screen-lg-min) {
}
