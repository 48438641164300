.navbar-toggle {
  margin: 0;
  padding: 15px 0 15px 15px;
  border: none;
  z-index: 9999;
  float: right;
  @media (max-width: $screen-md-min) {
    display: block;
  }
  .icon-bar {
    background: $gray;
    border-radius: 0;
    width: 30px;
    height: 3px;
    @include transition(all 0.2s);

    +.icon-bar {
      margin-top: 6px;
    }
  }

  .top-bar {
    @include rotate(45deg);
    @include transform-origin(10% 0%);
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    @include rotate(-45deg);
    @include transform-origin(0% 90%);
  }

  &.collapsed {
    .top-bar {
      @include rotate(0);
    }
    .middle-bar {
      opacity: 1;
    }
    .bottom-bar {
      @include rotate(0);
    }
  }
}