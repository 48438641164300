body#tinymce {
  margin: 12px !important;
}

@media screen and (max-width: $screen-xs-max) {
}
@media screen and (min-width: $screen-sm-min) {
}
@media screen and (min-width: $screen-md-min) {
}
@media screen and (min-width: $screen-lg-min) {
}
