.content-block.home_header {
  @extend .container-fluid;
  .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    @media (min-width: $screen-sm-min) {
      flex-wrap: nowrap;
    }
  }
  .home_header-left,
  .home_header-right {
    width: 100%;
    float: left;
    @media (min-width: $screen-sm-min) {
      width: 50%;
    }
  }
  .home_header-right {
    display: flex;
    align-items: center;
    justify-content: center;
    .wrapper-home_header {
      @include make-sm-column(12);
      @include make-md-column(9);
      @media (max-width: $screen-sm-min) {
        padding-top: $grid-gutter-width;
        padding-bottom: $grid-gutter-width;
      }
    }
  }
  .button {
    @extend .button;
  }
}
