// Grid system
.wrap {
  margin-top: 81px;
  @media (min-width: $screen-md-min) {
    margin-top: 107px;
  }
}
.main {
  @include make-sm-column($main-sm-columns);
}
