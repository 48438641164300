.banner {
  padding-top: $grid-gutter-width * 0.5;
  position: fixed;
  top: 0;
  width: 100%;
  background: $white;
  z-index: 9999;
  @media (min-width: $screen-md-min) {
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
  }
  .navbar-header {
    margin-bottom: $grid-gutter-width * 0.5;
    @include make-xs-column(12);
    @media (min-width: $screen-md-min) {
      margin-bottom: 0;
      padding-left: $grid-gutter-width;
    }
    @media (min-width: $screen-md-min) {
      width: 20%;
    }

    #logo {
      max-width: 70%;
      width: auto;
      height: auto;
      display: block;
      float: left;
    }
  }
  .navbar-menu {
    @include make-xs-column(8);
    @include make-md-column(12);
    background-color: $gray;
    width: 100%;
    min-height: 0px;
    @media (min-width: $screen-md-min) {
      width: 80%;
      background-color: transparent;
    }
    nav.nav-primary>ul {
      padding-top: $grid-gutter-width;
      padding-bottom: $grid-gutter-width * 0.75;
      @media (min-width: $screen-md-min) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    nav.navbar-collapse.collapse.in {
      display: block !important;
    }
    nav.navbar-collapse.collapse {
      @media (max-width: $screen-md-min) {
        display: none !important;
      }
    }
    .navbar-right {
      float: none !important;
      @media (min-width: $screen-md-min) {
        float: right !important;
      }
    }
  }
}
