.button {
  border: 1px solid $gray;
  color: $gray;
  padding: 4px 6px;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
  margin-bottom: $grid-gutter-width * 0.5;
  @media (min-width: $screen-md-min) {
    font-size: 16px;
  }
}