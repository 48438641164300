@mixin triangle($direction, $sizeH, $sizeV, $color) {
  position: absolute;
  top: 0;
  right: 0;
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  -moz-transform: scale(.9999);

  @if $direction == top {
    border-width: 0 $sizeV $sizeH $sizeV;
    border-color: transparent transparent $color transparent;
  }

  @if $direction == bottom {
    border-width: $sizeV $sizeH 0 $sizeV;
    border-color: $color transparent transparent transparent;
  }

  @if $direction == left {
    border-width: $sizeV $sizeH $sizeV 0;
    border-color: transparent $color transparent transparent;
  }

  @if $direction == right {
    border-width: $sizeV 0 $sizeV $sizeH;
    border-color: transparent transparent transparent $color;
  }

  @if $direction == topright {
    border-width: 0 $sizeH $sizeV 0;
    border-color: transparent $color transparent transparent;
  }

  @if $direction == bottomright {
    border-width: 0 0 $sizeH $sizeV;
    border-color: transparent transparent $color transparent;
  }

  @if $direction == bottomleft {
    border-width: $sizeH 0 0 $sizeV;
    border-color: transparent transparent transparent $color;
  }

  @if $direction == topleft {
    border-width: $sizeH $sizeV 0 0;
    border-color: $color transparent transparent transparent;
  }

}