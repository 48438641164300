// --------------------
// Project
// --------------------

// Glyphicons font path
$icon-font-path:        "../fonts/";
$font-path:             "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors

// --------------------
// Bootstrap
// --------------------

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$brand-primary:      #000000;
$gray:               #78777c;
$white:              #FFFFFF;
$beige:              #D8D1C9;
$red:                #f6303f;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               white;
//** Global text color on `<body>`.
$text-color:            black;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  Arial, sans-serif;

$font-size-base:          15px;

$font-size-h1:            floor(($font-size-base)); // ~15px
$font-size-h2:            floor(($font-size-base * 2)); // ~30px
$font-size-h3:            ceil(($font-size-base)); // ~15px

//** By default, this inherits from the `<body>`.
$headings-font-weight:    normal;
$headings-color:          $text-color;


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$border-radius-base:        0;
$border-radius-large:       0;
$border-radius-small:       0;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    white;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       gray;


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-default-color:              gray;
$btn-default-bg:                 white;
$btn-default-border:             gray;


//== Forms
//
//##

//** Text color for `<input>`s
$input-color:                    black;
//** `<input>` border color
$input-border:                   silver;

//** Border color for inputs on focus
$input-border-focus:             green;

//** Placeholder text color
$input-color-placeholder:        silver;

//** `.form-group` margin
$form-group-margin-bottom:       10px;

$legend-color:                   green;
$legend-border-color:            silver;
