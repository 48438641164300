article.post {
  display: inline-block;
  padding-bottom: $grid-gutter-width;
  padding-top: $grid-gutter-width * 1.25;
  figure {
    padding-bottom: $grid-gutter-width * 0.75;
    position: relative;
    .triangle {
      @include triangle(topright, 80px, 80px, $white);
    }
  }
  .button {
    margin-bottom: $grid-gutter-width * 0.5;
  }
}