.content-block.kapitel {
  @extend .container-fluid;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  @media (min-width: $screen-sm-min) {
    height: 25vh;
  }
  @media (min-width: $screen-md-min) {
    height: 30vh;
  }
  @media (min-width: $screen-lg-min) {
    height: 40vh;
  }
  .maintitle-bold-neg {
    margin-bottom: 0;
  }
  &.gallery {
    display: block;
    position: relative;
    .title-holder,
    .gallery-item{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .title-holder {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > .gallery-item {
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;

      &.active {
        opacity: 1;
      }

      &:first-child {
        position: relative;
      }
    }
  }
}
