.content-info {
  background-color: $gray;
  padding: ($grid-gutter-width * 1) 0;
  &, a {
    color: $white;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
  }
  a {
    text-decoration: underline;
  }
  .content-info-title {
    display: inline-block;
    padding-bottom: $grid-gutter-width * 0.5;
  }
  .content-info-column-links {
    @include make-sm-column(2);
    @media (max-width: $screen-sm-min) {
      margin-bottom: $grid-gutter-width;
    }
    ul {
      @extend .list-unstyled;
      li {
        margin-bottom: 10.5px;
      }
    }
  }
  .content-info-column-address {
    @include make-sm-column(4);
  }
}
